<template>
  <div>    
    <h1>Unauthorized Access</h1>
    <p>You do not have the necessary permissions to access this page.  If you feel this is in error, please contact:</p>
  </div>
</template>

<script>

export default {
  name: 'Unauthorized',
  components: {
  }
}
</script>
